<template>
  <div class="header__wrapper">
    <header :class="['header', { 'header--active': showDropdown }]">
      <div
        id="headerComponent"
        class="header__container"
      >
        <div class="header__top">
          <nuxt-link
            to="/"
            class="header__logo"
          >
            <img
              src="/v2/header/logo.svg"
              alt="logo"
            >
          </nuxt-link>

          <button
            class="m-btn header__menu-toggle"
            @click="toggleDropdown"
            @keydown.enter="toggleDropdown"
          >
            <img
              :src="`/v2/header/${showDropdown ? 'close' : 'menu'}.svg`"
              alt="arrow"
              :class="{ 'active': showDropdown }"
            >
            <span>меню</span>
          </button>

          <ul class="header__list">
            <li>
              <nuxt-link to="/online-school">
                о школе
              </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/articles">
                блог
              </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/news">
                новости
              </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/otzyvy">
                отзывы
              </nuxt-link>
            </li>
            <li>
              <button
                class="m-btn"
                @click="scrollToFaq"
              >
                вопросы
              </button>
            </li>
          </ul>

          <div class="header__actions">
            <a
              :href="`tel:${phone}`"
              target="_blank"
              class="header__phone"
            >
              <span>{{ phone | prettyPhone }}</span>
            </a>

            <a
              href="https://t.me/onlineschool_university_bot"
              target="_blank"
              rel="nofollow"
              class="header__actions_btn header__tg"
            >
              <img
                src="/v2/school-university/header/tg.svg"
                alt="tg"
              >
              <span>Написать</span>
            </a>

            <button
              class="m-btn header__actions_btn header__sign"
              @click="openSignIn"
            >
              <b class="header__sign_img">
                <img
                  src="/v2/school-university/header/user.svg"
                  alt="signin"
                >
              </b>
              <span>{{ user ? isDesktop ? 'ЛК' : 'Личный кабинет' : 'Войти' }}</span>
            </button>
          </div>
        </div>

        <div class="header__bottom">
          <nuxt-link to="/online-school">
            домашняя школа
          </nuxt-link>
          <nuxt-link to="/school-university">
            профильное обучение
          </nuxt-link>
          <nuxt-link to="/externat">
            экстернат
          </nuxt-link>
          <nuxt-link to="/ege">
            егэ
          </nuxt-link>
          <nuxt-link to="/oge">
            огэ
          </nuxt-link>
          <nuxt-link to="/catalog/repetitor">
            репетиторы
          </nuxt-link>
          <nuxt-link to="/catalog/programmirovanie-dlya-detej">
            программирование
          </nuxt-link>
          <nuxt-link to="/proforientacia">
            профориентация
          </nuxt-link>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.discount)"
          >
            скидки и акции
          </button>
        </div>
      </div>
    </header>

    <transition name="fade">
      <dropdown-menu
        v-if="showDropdown"
        class="header--desktop"
        @close-menu="showDropdown = false"
      />
    </transition>

    <transition name="fade">
      <dropdown-menu-mobile
        v-if="showDropdown"
        class="header--tablet"
      />
    </transition>

    <transition name="fade">
      <div
        v-if="showDropdown"
        class="header__overlay"
        @click="showDropdown = false"
      />
    </transition>

    <div class="header__mobile">
      <button
        :class="['m-btn header__mobile_menu', { 'opened': showDropdown }]"
        @click="toggleDropdown"
      >
        <i />
        <i />
        <i />
        <span>меню</span>
      </button>
      <a
        href="https://t.me/onlineschool_university_bot"
        target="_blank"
        rel="nofollow"
        class="header__mobile_tg"
      >
        <span>задать вопрос</span>
        <img src="/v2/header/tg.svg" alt="telegram">
      </a>
      <button
        class="m-btn header__mobile_consult"
        @click="openModal(MODAL_TYPES.menuConsult)"
      >
        <span>получить консультацию</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scrollTo from '~/utils/scrollTo';

import { MODAL_TYPES } from './data';
import DropdownMenu from './DropdownMenu.vue';
import DropdownMenuMobile from './DropdownMenuMobile.vue';

export default {
  name: 'AppHeader',
  components: {
    DropdownMenu,
    DropdownMenuMobile,
  },

  data: () => ({
    showDropdown: false,
    MODAL_TYPES,
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      isTablet: 'isTablet',
      isDesktop: 'isDesktop',
    }),
    ...mapGetters('modals', {
      videoModal: 'videoModal',
    }),
    routeName() {
      return this.$route.name;
    },
    phone() {
      return process.env.phone;
    },
  },
  watch: {
    routeName() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
    videoModal(newVal) {
      if (newVal && this.showDropdown) {
        this.showDropdown = false;
      }
    },
  },

  methods: {
    ...mapActions('modals', {
      toggleAuthModal: 'toggleAuth',
    }),
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),

    scrollToFaq() {
      return scrollTo.call(this, '.su-faq', '/');
    },

    async openSignIn() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }

      if (this.$cookies.get('auth')) {
        const user = await this.$api.getMe();

        if (user) {
          window.location = '/classroom';
          return;
        }

        this.$cookies.remove('auth');
      }

      this.toggleAuthModal();
      this.burgerState = false;
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  background-color: #ECEDF0;
  padding-bottom: calc(var(--scale) * 8);

  * {
    font-family: 'Onest', sans-serif;
  }

  &__container {
    padding-inline: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      padding-inline: calc(var(--scale) * 16);
    }

    @include media-down($size-mobile) {
      padding-inline: calc(var(--scale) * 12);
    }
  }

  &__top {
    position: relative;
    z-index: 8;
    height: calc(var(--scale) * 62);

    display: flex;
    align-items: center;
  }

  &__logo img {
     width: calc(var(--scale) * 185);

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 173);
    }
  }

  &__menu-toggle {
    margin-left: calc(var(--scale) * 124);

    display: flex;
    align-items: center;
    padding: calc(var(--scale) * 5) calc(var(--scale) * 16) calc(var(--scale) * 7) calc(var(--scale) * 6);
    gap: calc(var(--scale) * 8);

    background-color: #FF5319;
    border-radius: calc(var(--scale) * 100);

    @include media-down($size-tablet) {
      display: none;
    }

    img {
      width: calc(var(--scale) * 32);
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #FFFFFF;
    }
  }

  &__list {
    margin-left: calc(var(--scale) * 30);
    display: flex;
    align-items: center;
    gap: calc(var(--scale) * 2);

    @include media-down($size-tablet) {
      display: none;
    }

    a,
    button {
      padding: calc(var(--scale) * 8) calc(var(--scale) * 14) calc(var(--scale) * 10);

      font-style: normal;
      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #A7ABAC;
      white-space: nowrap;
    }

    @media (hover: hover) {
      &:hover {
        color: #5237E6;
      }
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: calc(var(--scale) * 4);

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 8);
    }

    &_btn {
      display: flex;
      align-items: center;
      padding: calc(var(--scale) * 6);
      padding-right: 1rem;
      gap: 0.5rem;

      background-color: #FFFFFF;
      border-radius: calc(var(--scale) * 100);
      transition: 300ms;

      @include media-down($size-tablet) {
        padding: 0;
        width: calc(var(--scale) * 32);
        height: calc(var(--scale) * 32);
        justify-content: center;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 130%;
        color: #02120F;

        @include media-down($size-tablet) {
          display: none;
        }
      }

      img {
        width: 2rem;
        height: 2rem;
        filter: invert(6%) sepia(11%) saturate(2664%) hue-rotate(119deg) brightness(90%) contrast(99%);
      }
    }
  }

  &__phone {
    padding: calc(var(--scale) * 9) calc(var(--scale) * 16);

    font-style: normal;
    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    color: #02120F;

    @include media-down($size-tablet) {
      display: grid;
      place-content: center;
      width: calc(var(--scale) * 32);
      height: calc(var(--scale) * 32);
      background-color: #FFFFFF;
      background-image: url("/v2/header/phone.svg");
      background-position: center;
      background-size: 2rem;
      background-repeat: no-repeat;
      border-radius: calc(var(--scale) * 100);

      span {
        display: none;
      }
    }
  }

  &__tg {
    @include media-down($size-tablet) {
      display: none;
    }

    &:hover {
      img {
        filter: invert(49%) sepia(98%) saturate(1164%) hue-rotate(169deg) brightness(85%) contrast(85%);
      }

      span {
        color: #2193CC;
      }
    }
  }

  &__sign {
    &_img {
      background-color: #ECEDF0;
      border-radius: 50%;
      height: 2rem;

      @include media-down($size-tablet) {
        height: 3rem;
        background-color: transparent;
      }
    }

    img {
      filter: invert(7%) sepia(39%) saturate(635%) hue-rotate(120deg) brightness(93%) contrast(102%);

      @include media-down($size-tablet) {
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      img {
        filter: invert(39%) sepia(97%) saturate(2048%) hue-rotate(347deg) brightness(103%) contrast(111%);
      }

      span {
        color: #FF5319;
      }
    }
  }

  &__bottom {
    display: flex;
    gap: calc(var(--scale) * 4);

    @include media-down($size-tablet) {
      width: calc(100% + var(--scale) * 32);
      margin-left: calc(-1 * var(--scale) * 16);
      padding-inline: calc(var(--scale) * 12);
      gap: calc(var(--scale) * 2);
      overflow-x: auto;
    }

    @include media-down($size-mobile) {
      width: calc(100% + var(--scale) * 24);
      margin-left: calc(-1 * var(--scale) * 12);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    a,
    button {
      padding: calc(var(--scale) * 8) calc(var(--scale) * 14) calc(var(--scale) * 10);
      background-color: #FFFFFF;
      border-radius: calc(var(--scale) * 40);
      transition: 200ms;

      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      color: #02120F;

      @media (hover: hover) {
        &:hover {
          color: #5237E6;
        }
      }

      @include media-down($size-tablet) {
        white-space: nowrap;
        flex-shrink: 0;
        padding: calc(var(--scale) * 6) calc(var(--scale) * 12) calc(var(--scale) * 8);
        font-size: calc(var(--scale) * 14);
      }
    }
  }

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 7;

    background-color: rgba(#02120F, .3);
    backdrop-filter: blur(5px);
  }

  &--active {
    background-color: #ECEDF0;

    .header__bottom {
      display: none;
    }
  }
}

.header__mobile {
  display: none;

  align-items: center;
  gap: calc(var(--scale) * 8);
  width: fit-content;
  position: fixed;
  z-index: 9;
  bottom: calc(var(--scale) * 16);
  left: 50%;
  transform: translateX(-50%);
  filter:
    drop-shadow(0 0 calc(var(--scale) * 10) rgba(15, 8, 59, 0.1))
    drop-shadow(0 calc(var(--scale) * 10) calc(var(--scale) * 10) rgba(15, 8, 59, 0.3));

  @include media-down($size-tablet) {
    display: flex;
  }

  @include media-down($size-mobile) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  ::v-deep(*) {
    font-family: "Onest", sans-serif;
    text-align: center;
  }

  &_menu {
    position: relative;
    padding: calc(var(--scale) * 14) calc(var(--scale) * 16) calc(var(--scale) * 16) calc(var(--scale) * 46);
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 16);

    @include media-down($size-mobile) {
      width: calc(var(--scale) * 110);
    }

    span {
      font-weight: 500;
      font-size: calc(var(--scale) * 18);
      line-height: 120%;
      color: #02120F;
    }

    i {
      position: absolute;
      height: calc(var(--scale) * 2);
      background-color: #FF5319;
      border-radius: 1px;
      transform-origin: left;
      transition: 200ms;

      &:nth-of-type(1) {
        width: calc(var(--scale) * 14);
        top: calc(var(--scale) * 19);
        left: calc(var(--scale) * 18);
      }

      &:nth-of-type(2) {
        width: calc(var(--scale) * 20);
        top: calc(var(--scale) * 25);
        left: calc(var(--scale) * 15);
      }

      &:nth-of-type(3) {
        width: calc(var(--scale) * 14);
        bottom: calc(var(--scale) * 19);
        left: calc(var(--scale) * 18);
      }
    }

    &.opened {
      i:nth-of-type(1) {
        width: calc(var(--scale) * 20);
        left: calc(var(--scale) * 15);
        top: calc(var(--scale) * 18);
        transform: rotate(45deg);
      }

      i:nth-of-type(2) {
        opacity: 0;
      }

      i:nth-of-type(3) {
        width: calc(var(--scale) * 20);
        left: calc(var(--scale) * 15);
        bottom: calc(var(--scale) * 18);
        transform: rotate(-45deg);
      }
    }
  }

  &_tg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--scale) * 8);
    padding-inline: calc(var(--scale) * 40);
    height: calc(var(--scale) * 52);

    background-color: #2193CC;
    border-radius: calc(var(--scale) * 16);

    @include media-down($size-mobile) {
      width: calc(var(--scale) * 248);
    }

    span {
      font-weight: 500;
      font-size: calc(var(--scale) * 18);
      line-height: 120%;
      color: #FFFFFF;
      white-space: nowrap;
    }

    img {
      filter: invert(100%) sepia(31%) saturate(162%) hue-rotate(250deg) brightness(111%) contrast(100%);
    }
  }

  &_consult {
    padding: calc(var(--scale) * 15) calc(var(--scale) * 19);
    background-color: #FF5319;
    border-radius: calc(var(--scale) * 16);

    font-weight: 500;
    font-size: calc(var(--scale) * 18);
    line-height: 120%;
    color: #FFFFFF;
    white-space: nowrap;

    @include media-down($size-mobile) {
      display: none;
    }
  }

  .header__mobile_menu.opened ~ .header__mobile_consult {
    @include media-down($size-mobile) {
      display: block;
      order: -1;
      width: calc(var(--scale) * 366);
    }
  }
}

.header--desktop {
  @include media-down($size-tablet) {
    display: none;
  }
}

.header--tablet {
  display: none;

  @include media-down($size-tablet) {
    display: block;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

::v-deep button,
::v-deep a {
  @include media-down($size-tablet) {
    &:focus-visible {
      outline: none;
    }
  }
}

</style>
