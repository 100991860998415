<template>
  <div class="dropdown-menu__mobile">
    <div
      v-for="(section, index) of navigationTabs"
      :key="index"
      class="dropdown-menu__items"
    >
      <div
        v-for="(tab, tabIndex) of section"
        :key="`${index}-${tabIndex}`"
        class="dropdown-menu__item"
      >
        <button
          :class="['m-btn', tab.colorClass, { 'opened': activeTabs.includes(tab.type) }]"
          @click="toggleMenu(tab.type)"
        >
          <span>{{ tab.label }}</span>
          <img
            src="/v2/header/arrow-down.svg"
            alt="arrow"
          >
        </button>
        <div class="dropdown-menu__content">
          <transition
            name="slide"
            mode="out-in"
          >
            <app-header-dropdowns
              v-if="activeTabs.includes(tab.type)"
              :type="tab.type"
              @close-menu="$emit('close-menu')"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeaderDropdowns from './AppHeaderDropdowns.vue';

import { PAGE_TYPES } from './data';

export default {
  name: 'DropdownMenuMobile',
  components: {
    AppHeaderDropdowns,
  },

  data: () => ({
    activeTabs: [],
    navigationTabs: [
      [
        { type: PAGE_TYPES.homeSchool, label: 'домашняя школа', colorClass: 'orange' },
        { type: PAGE_TYPES.oge, label: 'подготовка к ОГЭ', colorClass: 'blue' },
        { type: PAGE_TYPES.ege, label: 'подготовка к ЕГЭ', colorClass: 'blue' },
        { type: PAGE_TYPES.tutors, label: 'репетиторы', colorClass: 'violet' },
        { type: PAGE_TYPES.skills, label: 'развитие навыков', colorClass: 'green' },
        { type: PAGE_TYPES.it, label: 'it-курсы для детей', colorClass: 'light-green' },
      ],
      [
        { type: PAGE_TYPES.class10, label: '10-11 классы', colorClass: 'violet' },
        { type: PAGE_TYPES.class8, label: '8-9 классы', colorClass: 'violet' },
        { type: PAGE_TYPES.class5, label: '5-7 класс', colorClass: 'violet' },
        { type: PAGE_TYPES.class1, label: '1-4 класс', colorClass: 'violet' },
        { type: PAGE_TYPES.kids, label: 'дошкольники', colorClass: 'violet' },
        { type: PAGE_TYPES.adult, label: 'взрослым', colorClass: 'red' },
      ],
    ],
  }),

  created() {
    const pageName = this.$route.name;

    switch (pageName) {
      case 'oge':
      case 'oge-subpage':
        this.activeTab = PAGE_TYPES.oge;
        break;

      case 'ege':
      case 'ege-subpage':
        this.activeTab = PAGE_TYPES.ege;
        break;

      case 'repetitor':
      case 'repetitor-anglijskij-yazyk':
      case 'repetitor-matematika':
      case 'repetitor-biologiya':
      case 'repetitor-fizika':
      case 'repetitor-himiya':
      case 'repetitor-informatika':
      case 'repetitor-istoriya':
      case 'repetitor-obshchestvoznanie':
      case 'repetitor-russkij-yazyk':
      case 'catalog-repetitor':
      case 'catalog-repetitor-first':
      case 'catalog-repetitor-first-second':
      case 'catalog-repetitor-first-second-third':
        this.activeTab = PAGE_TYPES.tutors;
        break;

      case 'soft-skills':
        this.activeTab = PAGE_TYPES.skills;
        break;

      default:
        this.activeTab = PAGE_TYPES.homeSchool;
    }
  },

  methods: {
    toggleMenu(type) {
      if (this.activeTabs.includes(type)) {
        this.activeTabs = this.activeTabs.filter((tab) => tab !== type);
      } else {
        this.activeTabs.push(type);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu__mobile {
  position: fixed;
  left: 0;
  top: calc(var(--scale) * 62);
  z-index: 9;

  padding: calc(var(--scale) * 8) calc(var(--scale) * 12) calc(var(--scale) * 82);
  display: flex;
  flex-direction: column;
  gap: calc(var(--scale) * 20);
  width: 100%;
  max-height: calc(100vh - (var(--scale) * 62));
  height: 100%;
  overflow-y: auto;
  background-color: #ECEDF0;

  @include media-down($size-mobile) {
    padding-bottom: calc(var(--scale) * 136);
  }

  * {
    font-family: "Onest", sans-serif;
  }

  .dropdown-menu__items {
    margin-bottom: calc(var(--scale) * 16);
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 4);
  }

  .dropdown-menu__item {
    padding: calc(var(--scale) * 16);
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 16);

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        font-weight: 500;
        font-size: calc(var(--scale) * 20);
        line-height: 110%;
        letter-spacing: -0.02em;
      }

      img {
        transition: 200ms;
      }

      &.opened {
        margin-bottom: calc(var(--scale) * 30);

        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  button.orange span {
    color: #FF5319;
  }

  button.blue span {
    color: #0A4183;
  }

  button.violet span {
    color: #5237E6;
  }

  button.green span {
    color: #01410D;
  }

  button.light-green span {
    color: #01412E;
  }

  button.red span {
    color: #8D1717;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
